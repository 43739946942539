.nc-fill-container {
    min-height: calc(100vh - 153px);
}

.bg-primary-color {
    background-color: rgb(215, 152, 254);
}

.text-primary-color {
    color: rgb(215, 152, 254);
}

.border-radius-50 {
    border-radius: 50%;
}

.nc-CardCategory5 {
    margin: 0 10px;
}

.btm-gradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, rgb(0 0 0 / 48%) 30%, rgb(0 0 0 / 87%) 70%, rgb(0 0 0) 100%, rgb(0 0 0) 100%), no-repeat;
}
.ghost-image {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.word-outline-size{
    letter-spacing: 2px;
    font-size: 6rem;
    line-height: 5.5rem;
}
.word-outline{
    -webkit-text-stroke: 2px #787a79;
    -webkit-text-fill-color: transparent;
}

.word-outline-fill{
    -webkit-text-stroke: 2px rgba(229, 191, 0) !important;;
    -webkit-text-fill-color: rgba(229, 191, 0) !important;;
}

.hide-scroll{
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }
    
    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.swal-modal {
    background-color: rgb(43 43 43)
}
.swal-button {
    background-color: rgba(229, 191, 0);
    border: none;
    color: rgb(5, 5, 5);
}
.swal-button:hover {
    background-color: rgba(229, 191, 0) !important;
}
.swal-title, .swal-text {
    color: #fff;
}