.custom-input-dropdown {
    .dropdown {
        border-radius: 10px;
      }
      
      .dropdown-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .dropdown-body {
        display: none;
      }
      
      .dropdown-body.open {
        display: block;
      }
      
      .dropdown-item {
        padding: 10px;
      }
      
      .dropdown-item:hover {
        cursor: pointer;
      }
      
      .dropdown-item-dot {
        opacity: 0;
        color: #91A5BE;
        transition: all .2s ease-in-out;
      }
      
      .dropdown-item-dot.selected {
        opacity: 1;
      }
      
      .icon {
        font-size: 13px;
        color: #91A5BE;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
      }
      
      .icon.open {
        transform: rotate(90deg);
      }
}